<template>
    <component :is="tag" class="page-layout">
        <slot v-bind="layoutClasses" />
    </component>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: { type: String, default: 'div' },
    },

    computed: {
        layoutClasses() {
            return {
                inset: 'page-layout-inset',
                section: 'page-layout-section',
                stack: 'page-layout-stack',
            };
        },
    },
});
</script>

<style lang="postcss" scoped>
.page-layout {
    max-width: 90rem;
    margin: 0 auto;
}

:deep(.page-layout-inset) {
    margin-inline: calc(1rem + 1vw);
}

:deep(.page-layout-section) {
    margin-block: calc(2rem + 2vw);
}

:deep(.page-layout-stack > *) {
    margin-block: 1rem;
}
</style>
