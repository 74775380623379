<template>
    <router-link
        :to="{ name: 'project', params: { organizationSlug: project.clientGroup.slug, projectSlug: project.projectDetails.slug } }"
        class="project-card-root"
    >
        <v-row align="center" class="mt-n3">
            <v-col cols="auto">
                <universal-image v-if="project.projectDetails.image" :src="project.projectDetails.image" alt="" class="image" />
                <div v-else class="image" />
            </v-col>

            <v-col class="my-2">
                <h3 class="title">
                    <!-- <small v-if="local" style="float: right;">Local!</small> -->
                    {{ project.projectDetails.title }}
                </h3>

                <div v-if="project.regions.length !== 0" class="line-clamp regions">
                    <v-icon small>location_on</v-icon>
                    {{ project.regions.map(region => region.label).join(', ') }}
                    <!-- <small v-if="project._debugDistance">{{ project._debugDistance.toFixed(2) }} km</small> -->
                </div>

                <div>
                    With <strong>{{ project.clientGroup.name }}</strong>
                </div>

                <v-chip v-if="!project.projectDetails.published" small label class="mx-1">
                    <v-icon small left>edit</v-icon>
                    <strong>Not published</strong>
                </v-chip>
            </v-col>
        </v-row>

        <div class="line-clamp intro">
            <markdown-output :value="project.projectDetails.introduction" />
        </div>
    </router-link>
</template>

<script lang="ts">
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import UniversalImage from '@/components/UniversalImage.vue';
import { Project } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        UniversalImage,
        MarkdownOutput,
    },

    props: {
        project: { type: Object as () => Project, required: true },
        local: { type: Boolean, default: false },
    },
});
</script>

<style lang="postcss" scoped>
.line-clamp {
   /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  -webkit-line-clamp: var(--max-lines, 1);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.project-card-root {
    background: var(--color-white);
    border: 1px solid #8888;
    border-radius: var(--spacing-2);
    box-shadow: 0 5px 10px -10px black;
    color: var(--color-black);
    text-decoration: inherit;
    display: block;
    padding: var(--spacing-4);
    transition: border-color 150ms;
}

.project-card-root:hover {
    border-color: var(--color-primary);
}

.image {
    background: #8888;
    border-radius: var(--spacing-1);
    display: block;
    height: 110px;
    object-fit: cover;
    width: 110px;
}

.title {
    font-family: var(--type-sans);
    font-size: var(--type-lead);
    color: var(--color-primary);
    margin: 0;
}

.regions {
  --max-lines: 2;
}

.intro {
  --max-lines: 4;
}

.intro :deep(*) {
    display: inline !important;
}

.intro :deep(br) {
    content: "";
}

.intro :deep(br::after) {
    content: " ";
}
</style>
