<template>
    <!-- v-model is broken on Android and Vue won't fix it, weird! -->
    <!-- https://github.com/vuejs/vue/issues/9777 -->
    <textarea
        :value="value"
        @input="$emit('input', $event.target.value)"
        @change="$emit('change', $event.target.value)"
    />
</template>

<script lang="ts">
import Vue from '@/vueTyped';

const UNKNOWN = -Infinity;

export default Vue.extend({
    props: {
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            border: UNKNOWN as number,
            borderMeasurementTimeout: null as ReturnType<typeof setTimeout> | null,
        };
    },

    watch: {
        value: {
            immediate: true,
            async handler() {
                await this.$nextTick();
                this.handleValueChange();
            },
        },
    },

    methods: {
        async handleValueChange() {
            const textarea = this.$el as HTMLTextAreaElement;
            if (this.border === UNKNOWN) {
                this.measureBorders();
            }
            textarea.style.height = '0';
            await this.$nextTick();
            textarea.style.height = `${textarea.scrollHeight + this.border}px`;
        },

        measureBorders() {
            const style = getComputedStyle(this.$el);
            this.border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);

            if (this.borderMeasurementTimeout !== null) {
                clearTimeout(this.borderMeasurementTimeout);
            }

            this.borderMeasurementTimeout = setTimeout(() => {
                this.border = UNKNOWN;
            }, 2000);
        },
    },
});
</script>

<style lang="postcss" scoped>
    textarea {
        border: 1px solid #6668;
        min-height: 4em;
        padding: 0.3ch 0.6ch;
    }
</style>
