<template>
    <markdown-output v-if="readonly && markdown" :value="value" v-bind="$attrs" />

    <div v-else-if="readonly && !markdown">{{ value }}</div>

    <div v-else class="toggleable-textinput-container">
        <auto-height-text-area
            :value="value ?? ''"
            class="text-input"
            @input="$emit('input', $event)"
            @change="$emit('change', $event)"
        />

        <base-button
            v-if="dismissable"
            fab
            x-small
            color="var(--color-danger)"
            dark
            :aria-label="$t('actions.close')"
            class="close-button"
            @click="$emit('dismiss')"
        >
            <v-icon>close</v-icon>
        </base-button>
    </div>
</template>

<script lang="ts">
import AutoHeightTextArea from '@/components/AutoHeightTextArea.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';

export default {
    components: {
        AutoHeightTextArea,
        MarkdownOutput,
    },

    props: {
        dismissable: { type: Boolean, default: false },
        markdown: { type: Boolean, default: true },
        readonly: { type: Boolean, default: true },
        value: { type: String, default: '' },
    },
};
</script>

<style>
.toggleable-textinput-container {
    position: relative;
}

.text-input {
    display: block;
    min-height: 1em;
    width: 100%;
}

.close-button {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
}

.close-button:not(.toggleable-textinput-container:focus-within *) {
    display: none;
}
</style>
