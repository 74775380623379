<template>
    <div v-if="project">
        <header class="landing-page-header" :data-narrow="$vuetify.breakpoint.smAndDown">
            <div class="content-container">
                <primary-logo class="iseechange-logo" :height="$vuetify.breakpoint.mdAndUp ? 50 : 33" />
                <span class="header-joiner">+</span>
                <span class="organization-name">{{ project.clientGroup.name }}</span>
            </div>
        </header>

        <section class="hero">
            <universal-image v-if="project.projectDetails.image" :src="project.projectDetails.image" alt="" class="hero-background" />
            <div class="hero-underlay" />

            <div class="content-container hero-container">
                <v-row justify="center" justify-lg="space-between">
                    <v-col cols="11" lg="7" align-self="center" class="mb-16">
                        <div class="hero-content t-lead">
                            <template v-if="project.clientGroup.logo">
                                <span
                                    class="organization-logo-container"
                                    :style="`--organization-logo: url(${project.clientGroup.logo});`"
                                >
                                    <universal-image
                                        :src="project.clientGroup.logo"
                                        :alt="project.clientGroup.name"
                                        class="organization-logo"
                                    />
                                </span>
                            </template>

                            <h1 class="t-title my-8">{{ project.projectDetails.title }}</h1>
                            <markdown-output :value="project.projectDetails.introduction" />
                        </div>
                    </v-col>

                    <v-col cols="11" lg="4">
                        <article ref="registrationForm" class="registration-form-wrapper" :data-narrow="$vuetify.breakpoint.mdAndDown">
                            <header class="registration-form-header">
                                <h2 class="registration-form-heading">{{ $t('joinHeadline') }}</h2>
                            </header>

                            <div class="registration-form-content">
                                <registration-form
                                    embedded
                                    @success="handleRegistrationSuccess"
                                    @hook:mounted="handleRegistrationFormMounted"
                                    @hook:beforeDestroy="handleRegistrationFormDestroy"
                                />
                            </div>
                        </article>
                    </v-col>
                </v-row>
            </div>
        </section>

        <section v-if="project.projectDetails.prompts.length !== 0 || project.projectDetails.instructions" class="whitby-section">
            <v-container class="py-12">
                <v-row v-if="project.projectDetails.prompts.length !== 0" justify="center">
                    <v-col md="8">
                        <h2 class="section-heading text-center mb-8">{{ $t('helpUsUnderstand') }}</h2>
                        <ul>
                            <li v-for="prompt in project.projectDetails.prompts" :key="prompt">
                                <markdown-output :value="prompt" />
                            </li>
                        </ul>
                    </v-col>
                </v-row>

                <v-row v-if="project.projectDetails.instructions" justify="center">
                    <v-col md="8">
                        <h2 class="section-heading text-center mb-8">{{ $t('howToHelp') }}</h2>
                        <markdown-output :value="project.projectDetails.instructions" />
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="how-it-works py-11">
            <div class="content-container">
                <v-row justify="center">
                    <v-col cols="10">
                        <h2 class="section-heading text-center">{{ $t('howItWorksHeadline') }}</h2>

                        <v-row align="center" justify="center">
                            <v-col tag="ul" cols="12" md="6" class="unstyled-list">
                                <v-row tag="li" align="center" class="how-it-works-entry">
                                    <v-col cols="4" class="how-it-works-icon-wrapper">
                                        <img src="/img/campaigns/partners-shared/voice.png" alt="Abstract illustration of a globe flanked by weather symbols" class="how-it-works-icon">
                                    </v-col>

                                    <v-col class="how-it-works-explanation">
                                        <h3>{{ $t('addVoice') }}</h3>
                                    </v-col>
                                </v-row>

                                <v-row tag="li" align="center" class="how-it-works-entry">
                                    <v-col cols="4" class="how-it-works-icon-wrapper">
                                        <img src="/img/campaigns/partners-shared/community.png" alt="Abstract illustration of a neighborhood" class="how-it-works-icon">
                                    </v-col>

                                    <v-col class="how-it-works-explanation">
                                        <h3>{{ $t('impactCommunity') }}</h3>
                                    </v-col>
                                </v-row>

                                <v-row tag="li" align="center" class="how-it-works-entry">
                                    <v-col cols="4" class="how-it-works-icon-wrapper">
                                        <img src="/img/campaigns/partners-shared/any-time.png" alt="Abstract illustration of a human hand manipulating a phone" class="how-it-works-icon">
                                    </v-col>

                                    <v-col class="how-it-works-explanation">
                                        <h3>{{ $t('anyTime') }}</h3>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" md="6" class="screenshots-layout text-center">
                                <img src="/img/campaigns/partners-shared/post-form.jpg" alt="A screenshot of the Sighting creation form" width="303" class="screenshot">
                                <img src="/img/campaigns/partners-shared/example-post.jpg" alt="A screenshot of an example post" width="303" class="screenshot">
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </section>

        <div v-if="projectInvestigations.length !== 0" class="content-container my-15">
            <h2 class="section-heading text-center my-8">{{ $t('topicsHeadline') }}</h2>

            <v-row tag="ul" justify="center" class="unstyled-list">
                <v-col v-for="investigation in projectInvestigations" :key="investigation.route" tag="li" cols="10" sm="4">
                    <router-link :to="{ name: 'investigations.detail.description', params: { id: investigation.id } }" class="block-link">
                        <img v-if="investigation.backgroundImage" :src="investigation.backgroundImage | formatImage(640)" alt="" class="investigation-image">
                        <h3 class="t-subtitle text-center my-3">
                            <template v-if="$root.$te(`overrides.investigations.${investigation.slug}.name`)">
                                {{ $t(`overrides.investigations.${investigation.slug}.name`) }}
                            </template>
                            <template v-else>
                                {{ investigation.name }}
                            </template>
                        </h3>
                    </router-link>

                    <p>{{ investigation.cta }}</p>
                </v-col>
            </v-row>
        </div>

        <div v-if="fetchingPosts || posts.length !== 0" class="content-container my-15">
            <h2 class="section-heading text-center my-8">{{ $t('postsHeadline') }}</h2>

            <div v-if="fetchingPosts" style="height: 5em; position: relative;">
                <loading-indicator />
            </div>

            <v-row v-else tag="ul" justify="center" class="unstyled-list">
                <template v-for="post in posts">
                    <v-col
                        :key="post.id"
                        tag="li"
                        cols="10"
                        sm="6"
                        lg=""
                        style="display: flex;"
                    >
                        <div class="post-container" :style="{ '--post-image': post.photoObjs[0] ? `url('${$options.filters.formatImage(post.photoObjs[0], 640)}')` : null }">
                            <router-link :to="{ name: 'posts.detail', params: { id: post.id } }" class="block-link">
                                <div class="post-content pa-6">
                                    <time :datetime="post.observedAt">{{ post.observedAt | formatDate('MMMM D, Y') }}</time>
                                    <p class="t-lead">
                                        <template v-if="$root.$te(`overrides.posts.${post.id}.textBody`)">
                                            {{ $t(`overrides.posts.${post.id}.textBody`) }}
                                        </template>
                                        <template v-else>
                                            {{ post.textBody }}
                                        </template>
                                    </p>
                                </div>
                            </router-link>
                        </div>
                    </v-col>
                </template>
            </v-row>
        </div>

        <div v-if="project.projectDetails.partners.length !== 0" class="content-container my-15 text-center">
            <h2 class="section-heading my-8">{{ $t('partnersHeadline') }}</h2>

            <v-row tag="ul" justify="center" align="center" class="unstyled-list">
                <v-col v-for="partner in project.projectDetails.partners" :key="`${partner.name} ${partner.url}`" tag="li" cols="auto">
                    <component :is="partner.url ? 'a' : 'div'" :href="partner.url">
                        <figure>
                            <universal-image v-if="partner.logo" :src="partner.logo" :alt="partner.name" height="150" style="object-fit: contain;" />
                            <figcaption>{{ partner.name }}</figcaption>
                        </figure>
                    </component>
                </v-col>
            </v-row>
        </div>

        <section class="sharing py-15 text-center">
            <div class="content-container">
                <h2 class="section-heading mb-8">{{ $t('spreadTheWord') }}</h2>

                <share-network tag="button" network="twitter" v-bind="shareProps">
                    <v-icon large class="mr-8" style="color: inherit;">$twitter</v-icon>
                </share-network>

                <share-network tag="button" network="facebook" v-bind="shareProps">
                    <v-icon large class="mr-8" style="color: inherit;">$facebook</v-icon>
                </share-network>

                <share-network tag="button" network="linkedin" v-bind="shareProps">
                    <v-icon large class="mr-8" style="color: inherit;">$linkedin</v-icon>
                </share-network>

                <a :href="`mailto:?subject=${shareProps.title}&body=${shareProps.url}`" style="color: inherit; text-decoration: none;">
                    <v-icon large style="color: inherit;">email</v-icon>
                </a>
            </div>
        </section>

        <base-button
            v-if="!$store.state.account.currentUser"
            color="primary"
            class="sign-up-follower"
            :data-offscreen="formIsVisible"
            large
            @click="scrollToRegistrationForm"
        >Sign up</base-button>
    </div>

    <loading-indicator v-else-if="loading !== 0" />

    <four-oh-four v-else />
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { Investigation, Post, Project } from '@/types';
import orderBy from 'lodash/orderBy';
import FourOhFour from '@/pages/404.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import { MetaInfo } from 'vue-meta';
import RouteNames from '@/router/names';
import UniversalImage from '@/components/UniversalImage.vue';
import getShareProps from './get-share-props';

export default Vue.extend({
    components: {
        FourOhFour,
        LoadingIndicator,
        MarkdownOutput,
        PrimaryLogo,
        RegistrationForm,
        UniversalImage,
    },

    metaInfo(): MetaInfo {
        return {
            title: this.project ? `${this.project.projectDetails.title} (with ${this.project.clientGroup.name})` : '',
        };
    },

    i18n: {
        messages: {
            en: {
                joinHeadline: 'Join the ISeeChange community',
                helpUsUnderstand: 'Help us understand',
                howToHelp: 'How to start helping',
                howItWorksHeadline: 'How ISeeChange Works',
                addVoice: 'Add your voice',
                impactCommunity: 'Impact your community',
                anyTime: 'Participate any time',
                topicsHeadline: 'Topics',
                postsHeadline: 'Check out these sightings for inspiration',
                partnersHeadline: 'Additional partners',
                spreadTheWord: 'Help spread the word',
            },

            es: {
                joinHeadline: 'Únete a la comunidad de ISeeChange',
                helpUsUnderstand: 'Ayúdanos a entender',
                howToHelp: 'Cómo empezar a ayudar',
                howItWorksHeadline: 'Cómo funciona ISeeChange',
                addVoice: 'Suma tu voz',
                impactCommunity: 'Influye en tu comunidad',
                anyTime: 'Participa en cualquier momento',
                topicsHeadline: 'Temas',
                postsHeadline: 'Consulte estos avistamientos para inspirarse',
                partnersHeadline: 'Otros participantes',
                spreadTheWord: 'Ayude a difundir el mensaje',
            },
        },
    },

    data() {
        return {
            loading: 0,
            project: null as Project | null,
            scrollObserver: null as IntersectionObserver | null,
            formIsVisible: true,
            fetchingPosts: false,
            posts: [] as Post[],
        };
    },

    computed: {
        projectInvestigations(): Investigation[] {
            return this.$store.state.investigations.items.filter(investigation => {
                return this.project?.investigations.find(projectInvestigation => {
                    return investigation.id === projectInvestigation.id;
                });
            });
        },

        shareProps(): object {
            return this.project ? getShareProps(this.project) : {};
        },
    },

    watch: {
        '$route.path': {
            immediate: true,
            async handler() {
                try {
                    this.loading += 1;
                    const { organizationSlug, projectSlug } = this.$route.params;
                    const { data: project, error } = await this.$store.dispatch('fetchProject', { groupSlug: organizationSlug, slug: projectSlug });
                    if (error) throw error;
                    this.project = project;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading -= 1;
                }
            }
        },

        project: {
            immediate: true,
            handler(project) {
                if (project) {
                    this.fetchPosts();
                }
            }
        },
    },

    methods: {
        handleRegistrationFormMounted() {
            const navHeight = getComputedStyle(document.documentElement).getPropertyValue('--webnavigation--height');
            this.scrollObserver = new IntersectionObserver(this.handleIntersectionChange, { rootMargin: `-${navHeight.trim()} 0px` });
            this.scrollObserver.observe(this.$refs.registrationForm as Element);
        },

        handleRegistrationFormDestroy() {
            this.scrollObserver?.disconnect();
            this.scrollObserver = null;
        },

        handleIntersectionChange(entries: IntersectionObserverEntry[]) {
            const { intersectionRatio } = entries[0];
            this.formIsVisible = intersectionRatio !== 0;
        },

        async fetchPosts() {
            if (!this.project) return;

            this.fetchingPosts = true;
            const fetchingPostsFor = this.project.id;

            const posts: Post[] = await Promise.all(this.project.projectDetails.samplePosts.map(async (id: string) => {
                const { data } = await this.$store.dispatch('fetchPostById', { id });
                return data;
            }));

            const projectHasntChanged = this.project.id === fetchingPostsFor;
            if (projectHasntChanged) {
                this.posts = posts.filter(Boolean);

                for (const post of this.posts) {
                    post.photoObjs = orderBy(post.photoObjs, 'created_at') as Post['photoObjs'];
                }
            }

            this.fetchingPosts = false;
        },

        scrollToRegistrationForm() {
            (this.$refs.registrationForm as Element).scrollIntoView({ behavior: 'smooth' });
        },

        handleRegistrationSuccess() {
            this.$router.push({
                name: RouteNames.REGISTER,
                query: {
                    redirect: this.$router.resolve({
                        name: RouteNames.REGISTER,
                        query: {
                            ...this.$route.query,
                            show: 'apps',
                        }
                    }).href,
                },
            });
        },
    },
});
</script>

<style lang="postcss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.content-container {
    margin-inline: auto;
    max-width: min(1344px, calc(1344 / 1440 * 100vw));
}

.section-heading {
    font-size: calc(var(--type-title) * 0.85);
}

.block-link {
    text-decoration: none;
}

.unstyled-list {
    list-style: none;
    padding: 0;
}

.sign-up-follower {
    margin: var(--spacing-4);
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(0);
    transition: transform 1s;
    z-index: 1;
}

.sign-up-follower[data-offscreen] {
    transform: translateY(calc(var(--spacing-6) * -1)) translateY(-100%);
}

.landing-page-header {
    background: var(--color-secondary);
    box-shadow: 0 0 10px black;
    color: white;
    padding: var(--spacing-4);
    position: relative;
    text-align: center;
    z-index: 1;
}

.iseechange-logo {
    vertical-align: middle;
}

.header-joiner {
    display: inline-block;
    font-size: 40px;
    margin-inline: 1ch;
    vertical-align: middle;
}

[data-narrow] .header-joiner {
    font-size: 30px;
}

.organization-name {
    border-radius: 5px;
    display: inline-block;
    font-family: var(--type-headline);
    font-size: 40px;
    font-weight: bold;
    vertical-align: middle;
}

[data-narrow] .organization-name {
    font-size: 30px;
}

.hero {
    background: var(--color-secondary);
    color: white;
    padding: 5vh 0;
    position: relative;
}

.organization-logo-container {
    display: inline-block;
}

.organization-logo {
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 10px -5px black;
    height: 120px;
    object-fit: contain;
    padding: 10px;
    vertical-align: top;
    min-width: 250px;
}

[data-should-mask] .organization-logo {
    opacity: 0;
}

.hero-cta-button {
    text-shadow: none;
}

.hero-background {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.hero-underlay {
    background: linear-gradient(#0006 50%, #0000);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.hero-container {
    position: relative;
}

.hero-content {
    text-shadow: 0 2px 5px black;
}

.registration-form-wrapper {
    filter: drop-shadow(0 10px 10px #0003);
    margin-inline: auto;
    max-width: 60ch;
    scroll-margin-top: calc(var(--webnavigation--height) + var(--spacing-4));
}

.registration-form-wrapper:not([data-narrow]) {
    margin-bottom: calc(-5vh - 3em);
}

.registration-form-header {
    background: linear-gradient(135deg, #A2CD3D, #44ACAD);
    border-radius: 20px 20px 0 0;
    color: white;
    padding: var(--spacing-4) var(--spacing-8);
    text-align: center;
}

.registration-form-heading {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.7rem;
    letter-spacing: 0.05ch;
    margin: 0;
    text-transform: uppercase;
}

.registration-form-content {
    background: white;
    border-radius: 0 0 20px 20px;
    color: black;
    padding: var(--spacing-4) var(--spacing-8);
}

.girl-scouts {
    background: var(--color-light);
}

.girl-scouts-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

[data-smaller-icons] .girl-scouts-icon {
    height: auto;
    width: 80px;
}

.whitby-section {
    background: var(--color-light);
    font-size: calc(var(--type-lead));
}

.how-it-works {
    background: linear-gradient(#0000 75%, #0001);
}

.how-it-works-icon-wrapper {
    position: relative;
}

.how-it-works-entry:not(:last-child) > .how-it-works-icon-wrapper:after {
    background: var(--color-primary);
    content: "";
    height: 120%; /* This isn't perfect, but it's probably flexible enough. */
    left: 50%;
    margin-left: -0.75rem;
    position: absolute;
    top: 50%;
    width: 1.5rem;
}

.how-it-works-icon {
    /* margin-block: var(--spacing-8); */
    position: relative;
    z-index: 1;
}

.how-it-works-explanation {
    font-size: calc(var(--type-subtitle) / (24 / 16))1;
    margin-inline-end: 1em;
}

.how-it-works-explanation :deep(h3) {
    color: var(--color-danger);
    font-size: var(--type-subtitle);
}

.screenshots-layout {
    align-items: center;
    display: flex;
    justify-content: center;
}

.screenshots-layout > :first-child {
    margin: 4rem -4rem 0 0;
    position: relative;
    z-index: 1;
}

.screenshot {
    mask-image: url(/img/campaigns/partners-shared/phone-mask.png);
    mask-size: cover;
    min-width: 0;
}

.investigation-image {
    aspect-ratio: 20 / 13;
    object-fit: cover;
    width: 100%;
}

.post-container {
    background: linear-gradient(#0008 50%, #0000), var(--post-image, #888) 50% 50% / cover var(--color-secondary);
    width: 100%;
}

.post-content {
    aspect-ratio: 1;
    color: white;
}

.media {
    background: #8882;
}

.sharing {
    background: linear-gradient(135deg, #A2CD3D55, #44ACAD55);
}

.icon-link {
    color: inherit;
    text-decoration: inherit;
}
</style>
