<template>
    <page-layout v-if="project" v-slot="pageLayoutClasses" style="overflow: hidden;">
        <layout-row v-if="canTogglePublishedState" justify="space-between" :class="pageLayoutClasses.stack">
            <layout-column>
                <!-- <base-button>Save</base-button>{{ ' ' }} -->
                <base-button v-if="canEdit && editingAnything" @click="editNothing">{{ $t('controls.preview') }}</base-button>
            </layout-column>

            <layout-column>
                <base-button v-if="!project.projectDetails.published" :loading="loading !== 0" @click="saveChanges({ projectDetails: { published: true } }), editNothing()">{{ $t('controls.publish') }}</base-button>
                <base-button v-if="project.projectDetails.published" @click="saveChanges({ projectDetails: { published: false } })">{{ $t('controls.unpublish') }}</base-button>
            </layout-column>
        </layout-row>

        <heading-level>
            <div v-if="canEdit" style="float: right;">
                <base-button small fab @click="sectionsBeingEdited.intro = !sectionsBeingEdited.intro">
                    <v-icon>edit</v-icon>
                </base-button>
            </div>

            <layout-row tag="header" align="center" wrap :data-editing-this-section="sectionsBeingEdited.intro">
                <layout-column flex="20rem">
                    <div :class="[pageLayoutClasses.stack, pageLayoutClasses.inset]">
                        <div v-if="project.regions.length > 0">
                            <span v-for="region of project.regions" :key="region.id" class="byline-region">
                                <v-icon color="primary">location_on</v-icon>
                                {{ region.label }}
                            </span>
                        </div>

                        <div class="client-logo-container">
                            <div v-if="project.clientGroup.logo || sectionsBeingEdited.intro">
                                <image-input
                                    v-if="sectionsBeingEdited.intro"
                                    icon="edit"
                                    :text="project.clientGroup.logo ? false : 'Set a logo'"
                                    style="float: right;"
                                    @input="handleLogoSelection($event)"
                                />

                                <universal-image
                                    v-if="project.clientGroup.logo"
                                    :src="project.clientGroup.logo"
                                    alt=""
                                    class="client-logo"
                                />
                            </div>

                            <div v-if="!project.clientGroup.logo">{{ project.clientGroup.name }}</div>
                        </div>


                        <heading :small="useSmallIntro">
                            <toggleable-text-input
                                v-model="project.projectDetails.title"
                                :readonly="!sectionsBeingEdited.intro"
                                :inline="true"
                                @change="applyChanges({ projectDetails: { title: $event } })"
                            />
                        </heading>

                        <toggleable-text-input
                            v-model="project.projectDetails.introduction"
                            :readonly="!sectionsBeingEdited.intro"
                            :class="useSmallIntro ? '' : 'intro-text'"
                            @change="applyChanges({ projectDetails: { introduction: $event } })"
                        />

                        <div>
                            <base-button :to="{ name: 'posts.create' }" large color="primary">
                                {{ $t('addSighting') }}
                            </base-button>
                        </div>
                    </div>
                </layout-column>

                <layout-column flex="20rem" style="align-self: flex-start; position: relative;">
                    <div
                        v-if="sectionsBeingEdited.intro"
                        style="left: 50%; position: absolute; top: 50%; transform: translate(-50%, -50%);"
                    >
                        <image-input @input="handleImageSelection($event, 'image')" />
                    </div>

                    <universal-image
                        v-if="project.projectDetails.image"
                        :src="project.projectDetails.image"
                        alt=""
                        style="aspect-ratio: 1; display: block; width: 100%;"
                    />
                </layout-column>
            </layout-row>

            <div :class="pageLayoutClasses.inset">
                <div :data-editing-this-section="sectionsBeingEdited.helpUs">
                    <div v-if="canEdit" style="float: right;">
                        <base-button small fab @click="sectionsBeingEdited.helpUs = !sectionsBeingEdited.helpUs">
                            <v-icon>edit</v-icon>
                        </base-button>
                    </div>

                    <heading-level tag="section" :class="pageLayoutClasses.section">
                        <div class="narrow">
                            <heading>{{ $t('prompts.heading') }}</heading>

                            <toggleable-text-input
                                v-model="project.projectDetails.instructions"
                                :readonly="!sectionsBeingEdited.helpUs"
                                class="intro-text"
                                @change="applyChanges({ projectDetails: { instructions: $event } })"
                            />
                        </div>
                    </heading-level>

                    <section v-if="project.projectDetails.prompts.length !== 0 || sectionsBeingEdited.helpUs" class="help-us-understand reverse side-by-side" :class="pageLayoutClasses.section">
                        <div :class="pageLayoutClasses.stack">
                            <ul class="bar-list intro-text">
                                <heading-level v-for="(prompt, i) in project.projectDetails.prompts" :key="i" tag="li" :visual-modifier="+1">
                                    <toggleable-text-input
                                        v-if="project"
                                        v-model="project.projectDetails.prompts[i]"
                                        dismissable
                                        :readonly="!sectionsBeingEdited.helpUs"
                                        @change="applyChanges({ projectDetails: { prompts: project.projectDetails.prompts } })"
                                        @dismiss="project && project.projectDetails.prompts.splice(i, 1), applyChanges({ projectDetails: { prompts: project.projectDetails.prompts } })"
                                    />
                                </heading-level>

                                <div v-if="project.projectDetails.prompts.length === 0" style="text-align: center;">{{ $t('prompts.add') }}</div>
                            </ul>

                            <div v-if="sectionsBeingEdited.helpUs" style="text-align: center;">
                                <base-button fab color="primary" @click="project && project.projectDetails.prompts.push('')">
                                    <v-icon>add</v-icon>
                                </base-button>
                            </div>
                        </div>

                        <div
                            class="image-block"
                            style="text-align: center;"
                        >
                            <universal-image
                                :src="project.projectDetails.instructionsImage ?? require('@/assets/images/projects/screenshots.png')"
                                alt=""
                            />

                            <image-input
                                v-if="sectionsBeingEdited.helpUs"
                                icon="edit"
                                @input="handleImageSelection($event, 'instructionsImage')"
                            >Set an image</image-input>
                        </div>
                    </section>
                </div>

                <heading-level v-if="project.regions.length > 0 || canEdit" tag="section" :data-editing-this-section="sectionsBeingEdited.regions" style="text-align: center;">
                    <div v-if="canEdit" style="float: right;">
                        <base-button small fab @click="sectionsBeingEdited.regions = !sectionsBeingEdited.regions">
                            <v-icon>edit</v-icon>
                        </base-button>
                    </div>

                    <div :class="[pageLayoutClasses.section, pageLayoutClasses.stack]">
                        <heading>{{ $t('regions.heading') }}</heading>

                        <ul v-if="project.regions.length > 1 || canEdit" :key="sectionsBeingEdited.regions" class="region-links">
                            <li v-if="!sectionsBeingEdited.regions && project.regions.length === 0">{{ $t('regions.noneSelected') }}</li>

                            <li v-for="region of project.regions" :key="region.id">
                                <router-link :to="{ query: { ...$route.query, region: region.id } }" replace>
                                    {{ region.label }}
                                </router-link>

                                <template v-if="sectionsBeingEdited.regions">
                                    <button type="button" @click="editRegionName(region)">
                                        <v-icon small color="primary">edit</v-icon>
                                    </button>

                                    <button type="button" @click="removeRegion(region)">
                                        <v-icon small color="primary">close</v-icon>
                                    </button>
                                </template>
                            </li>

                            <li v-if="sectionsBeingEdited.regions">
                                <base-button x-small fab @click="addRegion">
                                    <v-icon>add</v-icon>
                                </base-button>
                            </li>
                        </ul>

                        <div v-if="selectedRegion" class="map-container">
                            <map-base no-zoom-on-scroll class="map">
                                <map-layer
                                    v-if="selectedRegion.geoRegionGeoJson"
                                    :key="selectedRegion.id"
                                    :data="selectedRegion.geoRegionGeoJson"
                                    :label="selectedRegion.label"
                                    color="gray"
                                    :width="5"
                                    autozoom
                                />
                            </map-base>
                        </div>
                    </div>
                </heading-level>

                <div v-if="project.investigations.length > 0 || canEdit" :data-editing-this-section="sectionsBeingEdited.topics">
                    <div v-if="canEdit" style="float: right;">
                        <base-button small fab @click="sectionsBeingEdited.topics = !sectionsBeingEdited.topics">
                            <v-icon>edit</v-icon>
                        </base-button>
                    </div>

                    <heading-level>
                        <heading style="text-align: center;">{{ $t('topics.heading') }}</heading>

                        <section class="reverse side-by-side" :class="pageLayoutClasses.section">
                            <div v-if="showInvestigationsCollage" class="collage">
                                <universal-image
                                    v-for="photo in investigationImages"
                                    :key="photo.id"
                                    :src="photo"
                                    alt=""
                                    :width="400"
                                />
                            </div>

                            <div>
                                <ul class="unstyled-list">
                                    <heading-level v-for="(topic, i) in project.investigations" :key="topic.id" tag="li" class="side-by-side">
                                        <div v-if="investigationImages[i] && !showInvestigationsCollage" class="image-block" style="align-self: flex-start; flex: 0.5 0 90px;">
                                            <universal-image
                                                :src="investigationImages[i]"
                                                alt=""
                                            />
                                        </div>

                                        <div>
                                            <heading>{{ topic.name }}</heading>
                                            <p>{{ topic.actionDescription }}</p>
                                        </div>
                                    </heading-level>

                                    <p v-if="project.investigations.length === 0" style="text-align: center;">
                                        <template v-if="sectionsBeingEdited.topics">{{ $t('investigations.chooseClick') }}</template>
                                        <template v-else>{{ $t('investigations.noneSelected') }}</template>
                                    </p>
                                </ul>

                                <div v-if="sectionsBeingEdited.topics">
                                    <div style="text-align: center;">
                                        <base-button fab color="primary" @click="topicSelectionModalOpen = true">
                                            <v-icon>edit</v-icon>
                                        </base-button>
                                    </div>

                                    <overlay-modal v-if="topicSelectionModalOpen" v-model="topicSelectionModalOpen" max-width="40ch" :no-card="false">
                                        <template #title>{{ $t('investigations.chooseFrom') }}</template>

                                        <form id="topic-selection-form" @submit.prevent="handleTopicSelection">
                                            <layout-row v-for="investigation in allInvestigations" :key="investigation.id" tag="label" gap="1ch">
                                                <layout-column>
                                                    <input
                                                        v-if="project"
                                                        type="checkbox"
                                                        name="investigations"
                                                        :value="investigation.id"
                                                        :checked="project.investigations.map(i => i.id).includes(investigation.id)"
                                                    >{{ ' ' }}
                                                </layout-column>
                                                <layout-column>
                                                    {{ investigation.name }}
                                                </layout-column>
                                            </layout-row>
                                        </form>

                                        <template #actions>
                                            <base-button type="submit" form="topic-selection-form" color="primary" @click="handleTopicSelection">Apply</base-button>
                                        </template>
                                    </overlay-modal>
                                </div>
                            </div>
                        </section>
                    </heading-level>
                </div>

                <div v-if="project.projectDetails.goals || canEdit" :data-editing-this-section="sectionsBeingEdited.goals">
                    <div v-if="canEdit" style="float: right;">
                        <base-button small fab @click="sectionsBeingEdited.goals = !sectionsBeingEdited.goals">
                            <v-icon>edit</v-icon>
                        </base-button>
                    </div>

                    <heading-level tag="section" :class="pageLayoutClasses.section">
                        <div class="narrow">
                            <heading>{{ $t('goals.heading') }}</heading>
                            <toggleable-text-input
                                v-model="project.projectDetails.goals"
                                :readonly="!sectionsBeingEdited.goals"
                                class="intro-text"
                                @change="applyChanges({ projectDetails: { goals: $event } })"
                            />
                        </div>
                    </heading-level>
                </div>

                <div :data-editing-this-section="sectionsBeingEdited.dataSharing">
                    <div v-if="canEdit" style="float: right;">
                        <base-button small fab @click="sectionsBeingEdited.dataSharing = !sectionsBeingEdited.dataSharing">
                            <v-icon>edit</v-icon>
                        </base-button>
                    </div>

                    <div :class="pageLayoutClasses.section">
                        <template v-if="smallScreen">
                            <div style="text-align: center;">
                                <base-button color="primary" @click="dataSharingModelOpen = true">{{ $t('transparency.learnMore') }}</base-button>
                            </div>
                            <overlay-modal v-model="dataSharingModelOpen" :no-card="false">
                                <data-sharing-content
                                    :project="project"
                                    :editing="sectionsBeingEdited.dataSharing"
                                    @input="applyChanges($event)"
                                />
                            </overlay-modal>
                        </template>
                        <template v-else>
                            <data-sharing-content
                                :project="project"
                                :editing="sectionsBeingEdited.dataSharing"
                                @input="applyChanges($event)"
                            />
                        </template>
                    </div>
                </div>

                <!-- <heading-level tag="section" class="dark-box" :class="pageLayoutClasses.section">
                    <layout-row justify="space-between" align="center" gap="0">
                        <layout-column :class="pageLayoutClasses.stack">
                            <div class="dark-box-content">
                                <heading>Learn more from our resources</heading>
                                <p>TODO: Learn more about how ISeeChange can help your community...</p>
                                <base-button href="#">Learn more</base-button>
                            </div>
                        </layout-column>

                        <layout-column>
                            <universal-image
                                src="https://placeholder.pics/svg/640x480"
                                alt=""
                                style="aspect-ratio: 1; display: block; width: 30rem;"
                            />
                        </layout-column>
                    </layout-row>
                </heading-level> -->

                <!-- <heading-level tag="section" class="dark-box" :class="pageLayoutClasses.section">
                    <layout-row justify="space-between" align="center">
                        <layout-column :class="pageLayoutClasses.stack">
                            <div class="dark-box-content">
                                <heading>Get started for free</heading>
                                <p>TODO: Content to come</p>
                            </div>
                        </layout-column>
                        <layout-column>
                            <div class="dark-box-content">
                                <universal-image
                                    src="https://placeholder.pics/svg/640x480"
                                    alt=""
                                    style="aspect-ratio: 1; display: block; width: 30rem;"
                                />
                            </div>
                        </layout-column>
                    </layout-row>
                </heading-level> -->

                <heading-level tag="section" :class="pageLayoutClasses.section">
                    <layout-row justify="space-between" align="center">
                        <layout-column>
                            <heading>{{ $t('share.heading') }}</heading>
                            <p>{{ $t('share.body') }}</p>
                        </layout-column>
                        <layout-column style="text-align: end;">
                            <share-network tag="button" type="button" network="facebook" v-bind="shareProps" style="padding: 0.5rem;">
                                <v-icon large color="#26f">$facebook</v-icon>
                            </share-network>
                            <share-network tag="button" type="button" network="twitter" v-bind="shareProps" style="padding: 0.5rem;">
                                <v-icon large color="#29f">$twitter</v-icon>
                            </share-network>
                            <share-network tag="button" type="button" network="linkedin" v-bind="shareProps" style="padding: 0.5rem;">
                                <v-icon large color="#15b">$linkedin</v-icon>
                            </share-network>
                            <share-network tag="button" type="button" network="telegram" v-bind="shareProps" style="padding: 0.5rem;">
                                <v-icon large color="#29d">$telegram</v-icon>
                            </share-network>
                            <share-network tag="button" type="button" network="tumblr" v-bind="shareProps" style="padding: 0.5rem;">
                                <v-icon large color="#245">$tumblr</v-icon>
                            </share-network>
                        </layout-column>
                    </layout-row>
                </heading-level>
            </div>
        </heading-level>
    </page-layout>
</template>

<script lang="ts">
import { apiClient } from '@/api-client';
import ImageInput from '@/components/ImageInput.vue';
import MapBase from '@/components/MapBase.vue';
import MapLayer from '@/components/MapLayer.vue';
import UniversalImage from '@/components/UniversalImage.vue';
import { CLIENT_ORIGIN } from '@/config';
import OverlayModal from '@/layouts/OverlayModal.vue';
import { ClientGroup, CurrentUser, Investigation, Project, Region } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import PageLayout from '@/ui/PageLayout.vue';
import Vue from '@/vueTyped';
import DataSharingContent from './DataSharingContent.vue';
import ToggleableTextInput from './ToggleableTextInput.vue';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                controls: {
                    preview: 'Preview',
                    publish: 'Publish',
                    unpublish: 'Unpublish to edit',
                },

                addSighting: 'Add a sighting',

                byline: {
                    with: 'With',
                },

                prompts: {
                    heading: 'Help us understand',
                    add: 'Click below to add a prompt',
                },

                instructions: {
                    heading: 'How you can help',
                },

                regions: {
                    heading: 'Regions we’re looking at',
                    noneSelected: 'No regions selected',
                },

                topics: {
                    heading: 'Topics we’re interested in',
                },

                investigations: {
                    chooseClick: 'Click below to choose topics',
                    noneSelected: 'No topics selected',
                    chooseFrom: 'Choose from the following topics,',
                },

                goals: {
                    heading: 'Goals of the project',
                },

                transparency: {
                    learnMore: 'Learn more about data privacy',
                },

                share: {
                    heading: 'Share with your community',
                    body: 'Invite your friends, family, and colleagues to get involved with this project.',
                },
            },

            es: {
                addSighting: 'Añadir un avistamiento',

                byline: {
                    with: 'con',
                },

                prompts: {
                    heading: 'Ayúdanos a entender',
                },

                instructions: {
                    heading: 'Cómo puedes ayudar',
                },

                regions: {
                    heading: 'Regiones en las que nos fijamos',
                },

                topics: {
                    heading: 'Temas que nos interesan',
                },

                goals: {
                    heading: 'Goals of the project',
                },

                transparency: {
                    learnMore: 'Más información sobre la privacidad de los datos',
                },

                share: {
                    heading: 'Comparte con tu comunidad',
                    body: 'Invita a tus amigos, familiares y colegas a participar en este proyecto.',
                },
            },
        },
    },

    metaInfo(): any {
        return {
            title: this.project ? `${this.project.projectDetails.title} (with ${this.project.clientGroup.name})` : "",
        };
    },

    components: {
        DataSharingContent,
        Heading,
        HeadingLevel,
        ImageInput,
        MapBase,
        MapLayer,
        OverlayModal,
        UniversalImage,
        LayoutColumn,
        LayoutRow,
        PageLayout,
        ToggleableTextInput,
    },

    props: {
        organizationSlug: { type: String, required: true },
        projectSlug: { type: String, required: true },
    },

    data() {
        return {
            loading: 0,
            dataSharingModelOpen: false,
            sectionsBeingEdited: {
                intro: false,
                helpUs: false,
                regions: false,
                topics: false,
                goals: false,
                dataSharing: false,
            },
            topicSelectionModalOpen: false,
        };
    },

    computed: {
        watchableProjectIdentifier(): string {
            return `${this.organizationSlug} ${this.projectSlug}`;
        },

        allInvestigations(): Investigation[] {
            return this.$store.state.investigations.items;
        },

        clientGroup(): ClientGroup | null {
            const clientGroups = this.$store.state.account.currentUser?.clientGroups ?? [];
            return clientGroups.find(clientGroup => clientGroup.id === this.project?.clientGroup.id) ?? null;
        },

        project(): Project | null {
            return this.$store.getters.project(this.organizationSlug, this.projectSlug);
        },

        selectedRegion(): Project['regions'][number] | null {
            const selectedId = `${this.$route.query.region ?? this.project?.regions[0]?.id ?? ''}`;
            return this.project?.regions.find(r => r.id === selectedId) ?? null;
        },

        useSmallIntro(): boolean {
            return (this.project?.projectDetails.title.length ?? 0) > 45;
        },

        investigationImages(): Investigation['backgroundImage'][] {
            return this.project?.investigations.map(investigation => {
                const fromStore = this.$store.state.investigations.items.find(i => i.id === investigation.id);
                return fromStore?.backgroundImage;
            }).filter(Boolean) as Investigation['backgroundImage'][];
        },

        smallScreen(): boolean {
            return !this.$vuetify.breakpoint.smAndUp; // TODO: Replace this
        },

        showInvestigationsCollage(): boolean {
            const investigationsCount = (this.project?.investigations.length ?? 0);
            return investigationsCount > 1 && investigationsCount <= 5 && !this.smallScreen // && this.project?.projectDetails.investigationsCollage;
        },

        editingAnything(): boolean {
            return Object.values(this.sectionsBeingEdited).some(Boolean);
        },

        shareProps(): object {
            return {
                url: new URL(location.pathname, CLIENT_ORIGIN).href,
                title: this.project?.projectDetails.title ?? 'ISeeChange',
                hashtags: 'iseechange',
            };
        },

        canTogglePublishedState(): boolean {
            const userClientGroups: CurrentUser['clientGroups'] = this.$store.state.account.currentUser?.clientGroups ?? [];

            const userIsISeeChange = userClientGroups!.some(group => group.isInternal);
            const userIsInProjectClientGroup = userClientGroups!.find(group => group.id === this.project?.clientGroup.id);

            const userIsCityOfMiami = userClientGroups!.some(group => group.slug === 'city-of-miami');
            const projectIsCityOfMiami = this.project?.clientGroup.slug === 'city-of-miami';

            return Boolean(
                userIsInProjectClientGroup
                && (
                    userIsISeeChange
                    || (projectIsCityOfMiami && userIsCityOfMiami)
                )
            );
        },

        canEdit(): boolean {
            return this.canTogglePublishedState && !this.project?.projectDetails.published;
        },
    },

    watch: {
        watchableProjectIdentifier: {
            immediate: true,
            handler() {
                try {
                    this.loading += 1;
                    this.$store.dispatch("fetchProject", { groupSlug: this.organizationSlug, slug: this.projectSlug, skipCache: true });
                } finally {
                    this.loading -= 1;
                }
            }
        },
    },

    methods: {
        async applyChanges(changes: Record<string, unknown>) {
            this.loading += 1;
            const { error: updateError } = await this.$store.dispatch('updateProject', {
                id: this.project?.id,
                ...changes,
            });
            this.loading -= 1;

            if (updateError) {
                alert(`Error updating project: ${JSON.stringify(updateError)}`);
            }
        },

        async handleImageSelection(files: File[], detailsKey: keyof Project['projectDetails']) {
            this.loading += 1;
            const { data: photo, error: imageUploadError } = await this.$store.dispatch('uploadMedia', { file: files[0] });

            if (imageUploadError) {
                alert(`Error uploading image: ${JSON.stringify(imageUploadError)}`);
                return;
            }

            this.applyChanges({ projectDetails: { [detailsKey]: photo.id } });
            this.loading -= 1;
        },

        async handleLogoSelection(files: File[]) {
            let newPhoto;

            try {
                this.loading += 1;

                if (files.length !== 0) {
                    const imageUpload = await this.$store.dispatch('uploadMedia', { file: files[0] });

                    if (imageUpload.error) {
                        alert(`Error uploading image: ${imageUpload.error}`);
                        return;
                    } else {
                        newPhoto = imageUpload.data;
                    }
                }

                const groupUpdate = await this.$store.state.apiClient.patch(`/client-groups/${this.project!.clientGroup.id}`, {
                    logo: newPhoto?.id ?? null,
                });

                if (groupUpdate.error) {
                    alert(`Error updating client group: ${groupUpdate.error}`);
                    return;
                } else {
                    this.$set(this.project!.clientGroup, 'logo', groupUpdate.data.clientGroup.logo);
                }
            } catch (error) {
                alert(`Error updating client group logo: ${error}`);
                return;
            } finally {
                this.loading -= 1;
            }
        },

        addRegion() {
            const SHAPEFILE_EXTENSIONS = ['dbf', 'prj', 'shp', 'shx'] as const;

            const input = document.createElement('input');
            input.type = 'file';
            input.accept = SHAPEFILE_EXTENSIONS.map(ext => `.${ext}`).join(',');
            input.multiple = true;

            input.onchange = async () => {
                try {
                    const formData = new FormData();

                    const files = Array.from(input.files ?? []);
                    for (const ext of SHAPEFILE_EXTENSIONS) {
                        const dotExt = `.${ext}`;
                        const file = files.find(f => f.name.endsWith(dotExt));
                        if (file) {
                            formData.append('files', file);
                            if (ext === 'shp') {
                                formData.set('label', file.name.slice(0, -1 * dotExt.length))
                            }
                        }
                    }

                    if (!formData.has('label')) {
                        throw new Error('A .shp file is required');
                    }

                    const response = await apiClient.post('/regions', formData);

                    if (response.status >= 200 && response.status < 300) {
                        const region = response.data;
                        this.clientGroup?.regions.push(region);

                        const regionIds = this.project?.regions.map(r => r.id) ?? [];
                        regionIds.push(region.id);

                        this.saveChanges({ regions: Array.from(regionIds) });
                    } else {
                        throw new Error(`${response.statusText} ${JSON.stringify(response.data)}`);
                    }
                } catch (error) {
                    console.error(error);
                    alert(`${error}`);
                } finally {
                    input.remove();
                }
            };

            document.body.append(input);
            input.click();
        },

        async editRegionName(region: Region) {
            try {
                const newLabel = prompt('Enter the name of this region', region.label);

                if (newLabel !== null && newLabel.trim() !== '') {
                    const formData = new FormData();
                    formData.set('label', newLabel);

                    const regionUpdate = await this.$store.state.apiClient.put(`/regions/${region.id}`, formData);

                    if (regionUpdate.error) {
                        throw regionUpdate.error;
                    }

                    region.label = newLabel;
                }
            } catch (error) {
                alert(error);
            }
        },

        async removeRegion(region: Project['regions'][number]) {
            try {
                const really = confirm(`Really delete the region "${region.label}"?`);
                if (!really) return;

                const regions = new Set(this.project!.regions.map(r => r.id));
                regions.delete(region.id);

                await this.saveChanges({ regions: Array.from(regions) });

                // Reload the client group through the current user.
                await new Promise(r => setTimeout(r, 250));
                const signInCheck = await this.$store.dispatch('checkIfSignedIn');
                const me: CurrentUser | null = signInCheck.data;
                const remainingClientGroupRegionIds = me?.clientGroups?.flatMap(g => g.regions).map(r => r.id) ?? [];
                const regionInUseElsewhere = remainingClientGroupRegionIds.includes(region.id);

                if (!regionInUseElsewhere) {
                    this.$store.state.apiClient.delete(`/regions/${region.id}`);
                }
            } catch (error) {
                alert(error);
            }
        },

        async handleTopicSelection(event: SubmitEvent) {
            if (event.target instanceof HTMLFormElement) {
                const fd = new FormData(event.target);
                const topicIds = fd.getAll('investigations');
                await this.applyChanges({ investigations: topicIds });
                this.topicSelectionModalOpen = false;
            }
        },

        editNothing() {
            const keys = Object.keys(this.sectionsBeingEdited) as (keyof typeof this.sectionsBeingEdited)[];
            keys.forEach((k) => this.sectionsBeingEdited[k] = false);
        },

        async saveChanges(changes: Record<string, any>) {
            if (this.project) {
                try {
                    await this.$store.dispatch('updateProject', { id: this.project.id, ...changes });
                } catch (error) {
                    alert(error);
                    console.error(error);
                }
            }
        },
    },
});
</script>

<style scoped>
    hr {
        border: 1px solid #0002;
        border-block-end-width: 0;
    }

    [data-editing-this-section] {
        border: 3px dashed var(--color-primary);
    }

    .narrow {
        margin-inline: auto;
        max-width: 50rem;
        text-align: center;
    }

    .side-by-side {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: calc(1rem + 2vw)
    }

    .side-by-side.reverse {
        flex-direction: row-reverse;
    }

    .side-by-side > * {
        flex: 1 1 20rem;
    }

    .side-by-side > .image-block > img {
        aspect-ratio: 1;
        object-fit: cover;
        width: 100%;
    }

    .byline-region {
        background: #8882;
        border-radius: 0.7ch;
        display: inline-block;
        font-size: var(--type-small);
        font-weight: bold;
        margin-inline-end: 1ch;
        padding: 0.1ch 0.8ch 0.1ch 0.4ch;
        white-space: nowrap;
    }

    .client-logo-container {
        align-items: center;
        display: flex;
        font-weight: bold;
        gap: 1rem;
    }

    .client-logo {
        display: block;
        height: 6rem;
        min-width: 1px;
    }

    .intro-text {
        color: var(--color-dark);
        font-size: var(--type-lead);
    }

    .map-container {
        aspect-ratio: 16 / 9;
        max-height: 75vh;
        width: 100%;
    }

    .map {
        border-radius: 0.8rem;
        height: 100%;
        width: 100%;
    }

    .collage {
        display: grid;
        gap: 1vw;
        grid-auto-columns: minmax(0, 1fr);
    }

    .collage > * {
        grid-column: span 2;
        border-radius: 0.4rem;
    }

    .collage::before {
        content: "";
        grid-row: 1;
    }

    .collage > :nth-child(2n-1) { grid-row: 2; }
    .collage > :nth-child(2n) { align-self: flex-end; grid-row: 1; }
    .collage > :nth-child(1) { aspect-ratio: 3 / 2; }
    .collage > :nth-child(2) { aspect-ratio: 1 / 1; }
    .collage > :nth-child(3) { aspect-ratio: 2 / 3; }
    .collage > :nth-child(4) { aspect-ratio: 2 / 3; }
    .collage > :nth-child(5) { aspect-ratio: 3 / 2; }
    .collage > :nth-child(6) { aspect-ratio: 1 / 1; }
    .collage > :nth-child(5) ~ * { display: none; }

    .bar-list,
    .unstyled-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    /* .bar-list > li {
        border-inline-start: 4px solid #8883;
        padding-inline-start: 1rem;
    } */

    :is(.bar-list, .unstyled-list) > li:not(:last-child) {
        margin-block-end: 1.5rem;
    }

    :is(.bar-list, .unstyled-list) > li > :first-child {
        margin-block-start: 0;
    }

    :is(.bar-list, .unstyled-list) > li > :last-child {
        margin-block-end: 0;
    }

    .region-links {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .region-links > li {
        display: inline;
        padding-inline: 1ch;
        white-space: nowrap;
    }

    .dark-box {
        background: var(--color-secondary);
        border-radius: 1rem;
        color: white;
        overflow: hidden;
    }

    .dark-box-content {
        padding: 0 4rem;
    }
</style>
