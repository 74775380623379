<template>
    <div>
        <label v-if="editing">
            <input
                type="checkbox"
                :checked="project.projectDetails.share_precise_location"
                @change="$emit('input', { projectDetails: { share_precise_location: $event.target.checked } })"
            >
            Precise location required
        </label>
        <layout-row tag="section" wrap>
            <layout-column flex="1 1 20rem">
                <heading-level :visual-modifier="+1" class="permissions-description">
                    <div class="permissions-content">
                        <heading>{{ $t('transparency.shared.heading') }} {{ project.clientGroup.name }}</heading>
                        <p v-if="infoSharingPartners.length !== 0" class="mt-n5">
                            {{ $tc('transparency.shared.andPartners', infoSharingPartners.length) }}
                            {{ ' ' }}
                            <span v-for="(partner, i) of infoSharingPartners" :key="partner.name">
                                <template v-if="infoSharingPartners.length > 1">
                                    <template v-if="i === infoSharingPartners.length - 1">, and </template>
                                    <template v-else-if="i !== 0">, </template>
                                </template>
                                <strong>{{ partner.name }}</strong>
                            </span>
                        </p>
                    </div>
                    <hr>
                    <div class="permissions-content">
                        <ul class="permissions-list">
                            <li data-value="yes">{{ $t('transparency.shared.name') }}</li>
                            <li data-value="yes">{{ $t('transparency.shared.postText') }}</li>
                            <li data-value="yes">{{ $t('transparency.shared.postImages') }}</li>
                            <li data-value="yes">
                                <template v-if="project.projectDetails.share_precise_location">{{ $t('transparency.shared.exactLocation') }}</template>
                                <template v-else>{{ $t('transparency.shared.generalLocation') }}</template>
                            </li>
                            <li data-value="yes">{{ $t('transparency.shared.structuredAnswers') }}</li>
                            <li data-value="yes">{{ $t('transparency.shared.weatherData') }}</li>
                        </ul>
                    </div>
                </heading-level>
            </layout-column>

            <layout-column flex="1 1 20rem">
                <heading-level :visual-modifier="+1" class="permissions-description">
                    <div class="permissions-content">
                        <heading>{{ $t('transparency.notShared.heading') }}</heading>
                    </div>
                    <hr>
                    <div class="permissions-content">
                        <ul class="permissions-list">
                            <li data-value="no">{{ $t('transparency.notShared.contactInfo') }}</li>
                            <li data-value="no">{{ $t('transparency.notShared.homeLocation') }}</li>
                            <li v-if="!project.projectDetails.share_precise_location" data-value="no">{{ $t('transparency.notShared.exactLocation') }}</li>
                            <li data-value="no">{{ $t('transparency.notShared.otherSightings') }}</li>
                            <li data-value="no">{{ $t('transparency.notShared.accountSettings') }}</li>
                        </ul>
                    </div>
                </heading-level>
            </layout-column>
        </layout-row>
    </div>
</template>

<script lang="ts">
import { Project } from '@/types';
import Heading from '@/ui/Heading.vue';
import HeadingLevel from '@/ui/HeadingLevel.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                transparency: {
                    shared: {
                        heading: 'What’s shared with',
                        andPartners: 'And partner | And partners',
                        name: 'Your full name (you can change this at anytime)',
                        postText: 'The text you add to your sightings',
                        postImages: 'Images you add to your sightings',
                        exactLocation: 'The exact location of your sightings',
                        generalLocation: 'The general location of your sightings (to the city or neighborhood level)',
                        structuredAnswers: 'Any data you add to your sightings (e.g. rain measurements, etc.)',
                        weatherData: 'External weather data synced to your sightings (the current temperature, humidity, precipitation, etc.)',
                    },

                    notShared: {
                        heading: 'What’s not shared?',
                        contactInfo: 'Your contact information',
                        homeLocation: 'Your account’s home location',
                        exactLocation: 'The exact location of your sightings',
                        otherSightings: 'Sightings unrelated to this project',
                        accountSettings: 'Your account settings',
                    },
                },
            },

            es: {
                transparency: {
                    shared: {
                        heading: 'Qué se comparte con',
                        andPartners: 'Y socio | Y socios',
                        name: 'Tu nombre completo (puedes cambiarlo en cualquier momento)',
                        postText: 'El texto que añadas a tus avistamientos',
                        postImages: 'Las imágenes que añadas a tus avistamientos',
                        exactLocation: 'La ubicación exacta de tus avistamientos',
                        generalLocation: 'La ubicación general de tus avistamientos (a nivel de ciudad o barrio)',
                        structuredAnswers: 'Cualquier dato que añadas a tus avistamientos (por ejemplo, medidas de lluvia, etc.)',
                        weatherData: 'Los datos meteorológicos externos sincronizados con sus avistamientos (la temperatura actual, la humedad, las ',
                    },

                    notShared: {
                        heading: '¿Qué no se comparte?',
                        contactInfo: 'Su información de contacto',
                        homeLocation: 'La ubicación de su cuenta',
                        exactLocation: 'La ubicación exacta de sus avistamientos',
                        otherSightings: 'Los avistamientos no relacionados con este proyecto',
                        accountSettings: 'La configuración de su cuenta',
                    },
                },
            },
        },
    },

    components: {
        Heading,
        HeadingLevel,
        LayoutRow,
        LayoutColumn
    },

    props: {
        project: { type: Object as () => Project, required: true },
        editing: { type: Boolean, default: false },
    },

    data() {
        return {
            dataSharingModelOpen: false,
        };
    },

    computed: {
        infoSharingPartners(): Project['projectDetails']['partners'] {
            // Removed in new design, but maybe they'll come back!
            // return this.project.projectDetails.partners.filter(partner => partner.infoShared);
            return [];
        },
    },
});
</script>

<style scoped>
    hr {
        border: 1px solid #0002;
        border-block-end-width: 0;
    }

    .permissions-content {
        margin: calc(1rem + 0.5vw);
    }

    .permissions-description {
        border: 1px solid #0002;
        border-radius: 1rem;
        box-shadow: 0 0.7rem 1rem #0001;
    }

    .permissions-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .permissions-list > li {
        display: flex;
    }

    .permissions-list > li:not(:last-child) {
        margin-block-end: 1ch;
    }

    .permissions-list > li::before {
        align-self: baseline;
        aspect-ratio: 1;
        background: gray;
        border-radius: 50%;
        content: "?";
        flex-shrink: 0;
        height: 2.7ch;
        line-height: 2.7ch;
        margin-inline-end: 1ch;
        text-align: center;
    }

    .permissions-list > li[data-value="yes"]::before {
        background: #dfd;
        color: var(--color-success);
        content: "✓";
    }

    .permissions-list > li[data-value="no"]::before {
        background: #fdd;
        color: var(--color-danger);
        content: "×";
    }
</style>
